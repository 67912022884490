<template>
  <div id="VacationRequestReviewView">
    <div class="row">
      <div class="col">
        <h4>review vacation requests</h4>
        <transition name="slide-fade">
          <app-alert v-if="alert.message" :type="alert.type" :message="alert.message" @alertclose="closeMessage" />
        </transition>
        <ul class="error">
          <li v-for="error in errors">
            {{ error.message }}
          </li>
        </ul>
        <!--<app-modal id="selectedRequestModal" size="large" v-if="showSelectedRequest" :static="false" :show="showSelectedRequest" :closeOnEscape="true" title="view request" :closeModalEvent="closeViewModalEvent">
        <div>
          <EmployeeInfoDisplay :employeeInfo="selectedRequestToView.employeeInfo" :vacationYear="selectedRequestToView.vacationMaster.vacationYear"></EmployeeInfoDisplay>
        </div>
        <hr />
        <RequestView :request="selectedRequestToView"></RequestView>
        <template v-slot:footer>
          <hr />
          <div class="col-md-12 text-center">
            <h5>Action/Log History Details</h5>
            <RequestActionList :requestActions="selectedRequestToView.requestActions"></RequestActionList>
          </div>
        </template>

      </app-modal>-->
        <app-modal :show="showApprovalOrRejectionModal" :static="true" size="medium" :title="selectedAction.description + ' requests'">
          <form id="actionForm" onsubmit="event.preventDefault();">
            <fieldset v-bind:disabled="isActioningRequests">
              <div class="form-group row mt-2">
                <!--<span class="font-weight-bold">Are you sure you want to {{ selectedAction.description }} this requst?</span>-->
                <span class="font-weight-bold">comment</span>
                <textarea v-model="actionComment" rows="5" class="form-control" v-bind:required="selectedAction !== actions.Approve"></textarea>
                <div class="invalid-feedback">
                  Please enter comments for this action.
                </div>
              </div>
            </fieldset>
          </form>
          <template v-slot:footer>
            <button class="btn btn-danger d-inline mr-2" @click.prevent="onDismissAction" v-bind:disabled="isActioningRequests">No</button>
            <button class="btn btn-primary d-inline mr-2" v-bind:class="{ spin: isActioningRequests }" @click.prevent="onActionRequestsConfirmed" v-bind:disabled="isActioningRequests">Yes<span class="spinner"></span></button>
          </template>
        </app-modal>
        <div>
          <employee-info-display v-if="employeeInfo.employeeId" :displayMode="true" :employeeInfo="employeeInfo" :vacationMaster="vacationMaster" :requestCanBeRaisedForEmployee="leaveRequestCanBeRaisedForEmployee" :unScheduledVacationDaysToDisplay="vacationMaster.unScheduledVacationDays" :unScheduledRnRDaysToDisplay="vacationMaster.unScheduledRnRDays"></employee-info-display>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col">
        <data-table v-if="requests.length" id='cashInLieu-requests-table' :headers='tableHeaders' :items='requests'>
          <!--<template v-slot:item.employeeName="{ item }">-->
          <!--<router-link :to="{ name: 'viewCashInLieuRequest', params: { id: item.id }}">
          {{ item.employeeName }}
        </router-link>-->
          <!--<a href="#" @click="openSelectedRequest(item)">{{ item.employeeName }}</a>
        </template>-->
          <template v-slot:item.isChecked="{ item }">
            <input type="checkbox" v-model="item.isChecked" v-bind:disabled="shouldDisableRequest(item)" />
          </template>
          <template v-slot:item.startDate="{ item }">
            {{ item.startDate | toShortDateString }}
          </template>
          <template v-slot:item.endDate="{ item }">
            {{ item.endDate | toShortDateString }}
          </template>
          <template v-slot:item.resumptionDate="{ item }">
            {{ item.resumptionDate | toShortDateString }}
          </template>
          <template v-slot:item.status="{ item }">
            {{ item.status | toRequestStatusDescription }}
          </template>
        </data-table>
        <div v-else-if="employeeInfo.employeeId" class="font-weight-bold">
          There are no vacation requests to review
        </div>
      </div>
      <div class="mt-4 col-md-12 text-center">
        <button class="btn btn-primary d-inline mr-2" v-if="showApproveButton" @click="approveRequests" title="Approve requests">Approve &#x2713;</button>
        <button class="btn btn-danger d-inline mr-2" v-if="showCancelButton" @click="cancelRequests" title="Cancel request">Cancel &#x2716;</button>
        <button class="btn btn-danger d-inline mr-2" v-else-if="showRejectButton" @click="rejectRequests" title="Reject request">Reject &#x2716;</button>
        <button class="btn btn-warning d-inline mr-2" v-if="showReturnButton" @click="returnRequests" title="Return request">Return &#x23CE;</button>
      </div>
    </div>
    <div class="row" v-if="requests.length">
      <div class="col">
        <h5>Action/Log History Details</h5>
        <RequestActionList v-if="checkedRequests.length == 1" :requestActions="checkedRequests[0].requestActions"></RequestActionList>
        <p v-else class="text-center font-weight-bold">Select only one request to show history </p>
      </div>
    </div>

  </div>
</template>

<style scoped lang="scss">

  .action-btn {
    cursor: pointer;
    font-size: 30px;
  }

  .slide-fade-enter-active {
    transition: all .5s ease;
  }

  .slide-fade-leave-active {
    transition: all .5s cubic-bezier(1.0, 0.5, 0.8, 1.0);
  }

  .slide-fade-enter, .slide-fade-leave-to
  /* .slide-fade-leave-active below version 2.1.8 */ {
    transform: translateX(10px);
    opacity: 0;
  }

  .error {
    text-align: left;
    color: #ac0c0c;
    list-style: none;
  }

  fieldset {
    border: 0;
  }

  button {
    position: relative;
    transition: all 1s;
  }

  .spin {
    padding-left: 2.5em;
    display: block;
  }

  .spin .spinner {
    left: -.6em;
    top: .4em;
    width: 2.5em;
    display: block;
    position: absolute;
  }

  /* spinner animation */
  @keyframes spinner {
    0% {
      transform: rotate(0deg);
    }

    100% {
      transform: rotate(360deg);
    }
  }

  /* The actual spinner element is a pseudo-element */
  .spin .spinner::before {
    content: "";
    width: 1.5em; /* Size of the spinner */
    height: 1.5em; /* Change as desired */
    position: absolute;
    top: 50%;
    left: 50%;
    border-radius: 50%;
    border: solid .35em #000; /* Thickness/color of spinner track */
    border-bottom-color: #555; /* Color of variant spinner piece */
    animation: .8s linear infinite spinner; /* speed of spinner */
    transform: translate(-50%, -50%);
    will-change: transform;
  }

  /* optional, but it will affect the size if changed */
  *, *::before, *::after {
    box-sizing: border-box;
  }
</style>

<style lang="scss">

  #selectedRequestModal {
    height: 90%;
  }

  #selectedRequestModal .modal-body-footer-container {
    height: 90%;
    overflow-y: auto
  }

  /*#selectedRequestModal > .modal-header {
    height: 10%
  }

  #selectedRequestModal > .modal-body {
    height: 50%;
    overflow-y: auto
  }

  #selectedRequestModal > .modal-footer {
    height: 35%;
    overflow-y: auto
  }*/

</style>

<script>
  import store from '@/store'
  import { mapState } from 'vuex'

  import { employeeService, vacationMasterService, vacationRequestService, } from '@/services'

  import Enums from '@/utils/enums'
  import eventBus from '@/utils/eventBus'
  import Events from '@/utils/events'
  import { CLOSE_MODAL_EVENT } from '@/utils/constants'

  import { toRequestStatusDescription } from '@/filters/enum'
  import { toShortDateString } from '@/filters'

  import EmployeeInfoDisplay from '@/components/vacation/EmployeeInfoDisplay'
  import DataTable from '@/components/ui/DataTable'
  import RequestView from '@/components/cashInLieu/RequestView'
  import RequestActionList from '@/components/leaveRequests/RequestActionList'

  import GeneralMixin from '@/mixins/GeneralMixin'
  import AccessControlMixin from '@/mixins/AccessControlMixin'
  import LeaveRequestMixin from '@/mixins/leaveRequest'
  import LeaveRequestActionsMixin from '@/mixins/leaveRequest/requestActions'


  export default {
    name: 'VacationRequestReviewView',
    components: {
      EmployeeInfoDisplay,
      DataTable,
      RequestView,
      RequestActionList
    },
    mixins: [GeneralMixin, AccessControlMixin, LeaveRequestMixin, LeaveRequestActionsMixin],
    data: () => ({
      requests: [],
      vacationMaster: {
        id: null,
        vacationYear: null,
        vacationDaysDue: 0,
        travelDays: 0,
        vacationAndTravelDays: 0,
        carriedOverDays: 0,
        unScheduledVacationDays: 0,
        unscheduledRnRDays: 0,
        buyOutDays: 0,
        vacationAllowanceRequest: {
          id: null,
          payMonth: 12,
          paymentStatus: 0,
          allowanceType: 0,
          version: null
        },
        cashInLieuRequests: [],
        version: null
      },
      employeeInfo: {
        employeeId: '',
        employeeNo: '',
        fullName: '',
        employmentDate: '',
        sbuStartDate: '',
        locationName: '',
        locationId: '',
        workScheduleName: '',
        workScheduleId: '',
        employeeTypeId: '',
        employeeTypeName: '',
        supervisorName: '',
        payrollCountryId: null,
        payrollCountry: null,
        alternateSupervisorName: '',
        isRegularEmployee: false,
        isExpatriateEmployee: false,
        isFieldEmployee: false,
        isResidentExpatriateEmployee: false,
        isShiftEmployee: false,
        isOnForeignService: false
      },
      tableHeaders: [
        {
          name: 'isChecked',
          title: ' '
        },
        {
          name: 'startDate',
          title: 'start date'
        },
        {
          name: 'endDate',
          title: 'end date'
        },
        {
          name: 'resumptionDate',
          title: 'resumption date'
        },
        {
          name: 'duration',
          title: 'duration'
        },
        {
          name: 'holidays',
          title: 'holidays'
        },
        {
          name: 'status',
          title: 'status'
        }
      ],
      showApprovalOrRejectionModal: false,
      showSelectedRequest: false,
      isActioningRequests: false,
      selectedRequestToView: null,
      closeViewModalEvent: 'CLOSE_VIEW_MODAL'
    }),
    computed: {
      ...mapState({
        appSettings: state => state.applicationSettingsModule.appSettings,
      }),
      checkedRequests() {
        return this.requests.filter(request => request.isChecked)
      },
      showCancelButton() {
        return (this.checkedRequests.length > 0 && (this.checkedRequests.length == this.checkedRequests.filter(request => this.canCancelRequest(request)).length))
      },
      showApproveButton() {
        return (this.checkedRequests.length > 0 && (this.checkedRequests.length == this.checkedRequests.filter(request => this.canApproveRequest(request)).length))
      },
      showRejectButton() {
        return (this.checkedRequests.length > 0 && (this.checkedRequests.length == this.checkedRequests.filter(request => this.canRejectRequest(request)).length))
      },
      showReturnButton() {
        return (this.checkedRequests.length > 0 && (this.checkedRequests.length == this.checkedRequests.filter(request => this.canReturnRequest(request)).length))
      }
    },
    filters: {
      toShortDateString,
      toRequestStatusDescription
    },
    methods: {
      shouldDisableRequest(request) {
        return request.status != Enums.RequestStatus.Submitted.value && request.status != Enums.RequestStatus.Edited.value
      },
      fetchRequests(id, employeeId) {
        const self = this
        eventBus.$emit(Events.LongOperationStarted, '')
        let tasks = []
        tasks.push(employeeService.getEmployee(employeeId))
        tasks.push(vacationMasterService.getVacationMasterForVacationRequest(id))
        Promise.all(tasks).then((results) => {
          const employee = results[0].data
          const vacationMaster = results[1]
          this.setData(employee, vacationMaster, id)
        }).catch((error) => {
          self.showErrorMessage(error)
        }).finally(() => {
          eventBus.$emit(Events.LongOperationCompleted)
        })
      },
      setData(employee, vacationMaster, id) {
        this.setEmployeeData(employee)
        this.employeeInfo.employmentDate = employee.employmentDate
        this.employeeInfo.sbuStartDate = employee.sbuStartDate
        this.employeeInfo.payrollCountryId = employee.payrollCountryId
        this.employeeInfo.payrollCountry = employee.payrollCountry
        this.employeeInfo.isShiftEmployee = employee.isShiftEmployee
        this.employeeInfo.isResidentExpatriateEmployee = employee.isResidentExpatriateEmployee
        this.setVacationData(vacationMaster, id)
      },
      setVacationData(vacationMaster, id) {
        const self = this
        this.vacationMaster.id = vacationMaster.id
        this.vacationMaster.vacationYear = vacationMaster.vacationYear
        this.vacationMaster.vacationDaysDue = vacationMaster.vacationDaysDue
        this.vacationMaster.travelDays = vacationMaster.travelDays
        this.vacationMaster.vacationAndTravelDays = vacationMaster.vacationAndTravelDays
        this.vacationMaster.carriedOverDays = vacationMaster.carriedOverDays
        this.vacationMaster.unScheduledVacationDays = vacationMaster.unscheduledVacationDays
        this.vacationMaster.unscheduledRnRDays = vacationMaster.unscheduledRnRDays
        this.vacationMaster.version = vacationMaster.version

        if (vacationMaster.vacationRequests.length) {
          vacationMaster.vacationRequests.forEach(request => {
            request.isChecked = (request.id == id)
          })
          self.requests = [...vacationMaster.vacationRequests].filter((request) => 
            this.canCancelRequest(request) || this.canApproveRequest(request) || this.canRejectRequest(request) || this.canReturnRequest(request)
          )
        }

      },
      openSelectedRequest(request) {
        this.selectedRequestToView = request
        this.showSelectedRequest = true
      },
      closeSelectedRequest() {
        this.selectedRequestToView = null
        this.showSelectedRequest = false
      },
      cancelRequests() {
        this.actionRequests(Enums.Actions.Cancel, Enums.RequestStatus.Cancelled)
      },
      approveRequests() {
        this.actionRequests(Enums.Actions.Approve, Enums.RequestStatus.Approved)
      },
      rejectRequests() {
        this.actionRequests(Enums.Actions.Reject, Enums.RequestStatus.Rejected)
      },
      returnRequests() {
        this.actionRequests(Enums.Actions.Return, Enums.RequestStatus.Returned)
      },
      actionRequests(action, nextRequestStatus) {
        this.selectedAction = action
        this.nextRequestStatus = nextRequestStatus
        this.showApprovalOrRejectionModal = true
      },
      onActionRequestsConfirmed() {
        const self = this
        const requestForm = document.getElementById('actionForm')
        if (requestForm.checkValidity() === false) {
          requestForm.classList.add('was-validated');
        }
        else {
          self.isActioningRequests = true
          store.dispatch(self.selectedAction.actionName, { requestIds: this.checkedRequests.map(a => a.id), requestType: Enums.RequestType.Vacation.value, comment: self.actionComment })
            .then(() => {
              self.showSuccessMessage(`request ${self.selectedAction.postActionText} successfully`)
              //alert(self.nextRequestStatus)
              this.checkedRequests.forEach(request => {
                request.status = self.nextRequestStatus.value
                request.isChecked = false
              })
              self.actionComment = null
              self.showApprovalOrRejectionModal = false
              self.selectedRequestToAction = null
            })
            .catch((error) => {
              self.showErrorMessage(error)
            }).finally(() => {
              self.isActioningRequests = false
            })
        }
      },
      onDismissAction() {
        this.actionComment = null
        this.showApprovalOrRejectionModal = false
      }
    },
    mounted() {
      eventBus.$on(this.closeViewModalEvent, this.closeSelectedRequest)
      eventBus.$on(CLOSE_MODAL_EVENT, this.onDismissAction)
    },
    beforeRouteEnter(to, from, next) {
      next(vm => {
        if (vm.$route.params.employeeId) {
          vm.fetchRequests(vm.$route.params.id, vm.$route.params.employeeId)
        }
        else {
          vm.fetchRequests(vm.$route.params.id, vm.currentUser.employeeId)
        }
      })
    }
  }
</script>
